const genres: string[] = [
    "Alternative",
    "Blues",
    "Children's",
    "Classical",
    "Classic Rock",
    "Country",
    "Country Rock",
    "Dance",
    "Disco",
    "Easy Listening",
    "Electronic",
    "Folk",
    "Funk",
    "Grunge",
    "Holiday",
    "Indie Rock",
    "International",
    "Jazz",
    "Oldies",
    "Opera",
    "Other",
    "Pop",
    "Prog Rock",
    "Psychedelic Rock",
    "Punk",
    "Rap",
    "Reggae",
    "Rock",
    "Soft Rock",
    "Ska",
    "Soul",
    "Soundtrack"
  ];

  export default genres;